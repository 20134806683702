import req from "./request";

export default {
  show: async (id, projectingId, projectingSubdirectoryId = null) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let url = `projectings/documents/${id}?building_id=${building.id}&projecting_id=${projectingId}`;

      const { data } = await req.get(
        `projectings/documents/${id}?building_id=${building.id}&projecting_id=${projectingId}`,
        true
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  store: async (data, projectingId, projectingSubdirectoryId = null) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let url = `projectings/documents?building_id=${building.id}&projecting_id=${projectingId}`;
      if (projectingSubdirectoryId)
        url += `&projecting_subdirectory_id=${projectingSubdirectoryId}`;
      const response = await req.post(url, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  update: async (id, data, projectingId, projectingSubdirectoryId = null) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let url = `projectings/documents/${id}?building_id=${building.id}&projecting_id=${projectingId}`;
      if (projectingSubdirectoryId)
        url += `&projecting_subdirectory_id=${projectingSubdirectoryId}`;
      const response = await req.put(url, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
  bulkRemoveProjectigDocuments: async (projectingId, data) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      let url = `projectings/documents/destroy?building_id=${building.id}&projecting_id=${projectingId}`;
      const response = await req.post(url, { ids: data }, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
