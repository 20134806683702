import req from "./request";

export default {
  index: async (projectingId) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      let url = `projectings/subdirectories?building_id=${building.id}&projecting_id=${projectingId}`;
      const { data } = await req.get(url, true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  show: async (id, projectingId) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      const { data } = await req.get(
        `projectings/subdirectories/${id}?building_id=${building.id}&projecting_id=${projectingId}`,
        true
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  store: async (data, projectingId) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      const response = await req.post(
        `projectings/subdirectories?building_id=${building.id}&projecting_id=${projectingId}`,
        data,
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  update: async (id, projectingId, data) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      const response = await req.put(
        `projectings/subdirectories/${id}?building_id=${building.id}&projecting_id=${projectingId}`,
        data,
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};
